.maintenance {
    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 24px;
    }

    &__date {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        text-transform: capitalize;
        span{
            text-transform: initial;
        }
    }

    &__description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        margin-bottom: 16px;

        &__detail {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
        }
    }

    &__detail {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        margin-top: 8px;
    }
    &__body{
        padding: 16px 0;
        border-bottom: 1px solid $primary-ligth;
        border-top: 1px solid $b_footer;
       margin-top: 16px;
    }
    &__btn{
        margin-top: 16px;
        display: flex;
        justify-content:flex-end;
    }
}