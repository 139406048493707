.mounth {
    &__container {
        display: flex;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        border-bottom: 1px solid #212121;
        margin-bottom: 16px;
        text-transform: capitalize;
    }
}