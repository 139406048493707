 html {
   margin: 0;
   padding: 0;
   color: #000;
 }



 html,
 body {
   font-family: $base-font-family !important;
   color: $primary;
   overflow-x: hidden;
   width: 100vw;
   min-height: 100vh;
   padding-right: 0 !important;
 }

 .body {
   min-height: calc(100vh - 150px);
   margin-top: 40px !important;
 }

 h1 {
   font-style: normal;
   font-weight: bold;
   font-size: 22px;
   line-height: 100%;
   margin-bottom: 0;
 }

 textarea:focus {
   outline: none;
 }

 li {
   list-style: none;
 }

 .mt-20 {
   margin-top: 20px;
 }

 .mr-10 {
   margin-right: 10px;
 }

 .accordion {
   border: none !important;
 }

 a {
   text-align: center;
   color: $important;
   text-decoration: none;

   &:hover {
     color: $important;

   }
 }

 .nav-tabs-content {
   width: auto;
   margin: 0 -15px;

   @include desktop {
     margin: 0;


   }

 }

 .nav-item {
   margin-left: 10px;

   @include desktop {
     margin-left: 0;
   }
 }

 .nav-link {
   text-align: center;
   text-decoration: none;
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   line-height: 100%;
   color: #878787;
   margin-right: 13px;
   cursor: pointer;
   position: relative;
   white-space: nowrap;
 }

 .nav-link:hover,
 .nav-link:focus {
   color: #212121;
 }

 .p-20 {
   padding: 20px;
 }

 .ml-0 {
   margin-left: 0 !important;
 }

 .link {
   text-align: center;
   color: $important;
   text-decoration: underline;
   cursor: pointer;
 }

 .no-link {
   text-align: center;
   color: inherit;
   text-decoration: none;
 }

 a,
 button,
 select,
 label {
   cursor: pointer;
 }

 .hide {
   display: none;
 }

 .opacity {
   opacity: 0;
 }

 .hide-responsive {
   display: none !important;

   @include desktop {
     display: inherit !important;
   }
 }

 .hide-desktop {
   @include desktop {
     display: none !important;
   }
 }

 .bold {
   font-weight: bold;
 }

 .bold-2 {
   font-weight: bold;
   color: $primary-bold;
 }

 .show {
   display: block;
 }

 .border-0 {
   border-top: none !important;
   border-bottom: none !important;
   border-left: none !important;
   border-right: none !important;
 }

 input,
 select,
 .border-special {
   border: #212121 1px solid !important;
   border-radius: 64px !important;

   &:focus {
     outline: transparent;
     border: #03A9F4 1px solid !important;
     box-shadow: 0px 5px 10px 0px #0394D626 !important;

   }
 }

 .border-special {
   overflow: hidden !important;
   border-radius: 24px !important;
   display: flex;
   &:focus {
    outline: transparent;
    border: #03A9F4 1px solid !important;
    box-shadow: 0px 5px 10px 0px #0394D626 !important;

  }
  &:active {
    outline: transparent;
    border: #03A9F4 1px solid !important;
    box-shadow: 0px 5px 10px 0px #0394D626 !important;

  }
  &__focus {
    outline: transparent;
    border: #03A9F4 1px solid !important;
    box-shadow: 0px 5px 10px 0px #0394D626 !important;

  }
 }

 textarea {
   border-radius: 24px !important;
   overflow-y: auto !important;
   border: none !important;
   
   &::-webkit-scrollbar {
     background: transparent;
     width: 8px;

   }

   &::-webkit-scrollbar-thumb {
     background: #BCBCBC;
     width: 10px;
     padding-right: 15px;
     border-radius: 24px;

   }


 }

 .clearfix.main__clearfix,
 .node.node-webform.clearfix {
   &:after {
     content: "" !important;
     display: none !important;
   }
 }

 .flex {
   display: flex;

   &--start {
     &-a {
       align-items: flex-start;
     }

     &-j {
       justify-content: flex-start;
     }

     align-items: flex-start;
     justify-content: flex-start;
   }

   &--center {
     &-a {
       align-items: center;
     }

     &-j {
       justify-content: center;
     }

     align-items: center;
     justify-content: center;
   }

   &--end {
     &-a {
       align-items: flex-end;
     }

     &-j {
       justify-content: flex-end;
     }

     align-items: flex-end;
     justify-content: flex-end;
   }
 }

 .pagination {
   display: flex;
   align-items: center;
   justify-content: center;

   li {
     background: $primary-bold;
     border-radius: 2px;
     min-width: 20px;
     min-height: 16px;
     display: flex;
     align-items: center;
     justify-content: center;
     opacity: 0.5;

     &.active {
       opacity: 1;
     }

     margin: 3px;

     a {
       font-family: "Roboto";
       font-style: normal;
       font-weight: bold;
       font-size: 10px;
       line-height: 16px;
       text-align: center;
       color: #f2f2f2;

     }
   }

   .previous,
   .next {
     background: transparent;

     a {
       font-size: 20px;
       color: $primary;
     }

     &.disabled {
       a {
         opacity: 0.5;
       }
     }
   }
 }

 .content {
   margin: 0 auto;
   padding: 0 15px;
   max-width: 1600px;

   @include mobile {}

   @include tablet {
     padding: 0 60px;
   }

   @include tablet-l {}



   @include lg-min {
     padding: 0 80px;
   }
 }

 .pointer {
   cursor: pointer;
 }

 .no-pointer {
   cursor: default;
 }

 @media (min-width: 1200px) {
   .col-xl-6 {
     flex: 0 0 auto;
     width: 100%;
   }
 }

 @media (min-width: 1350px) {
   .col-xl-6 {
     flex: 0 0 auto;
     width: 50%;
   }
 }

 #root {


   [data-amplify-authenticator] [data-state=inactive][role=tab] {
     display: none;
   }



   .amplify-tabs-item[data-state=active] {
     position: relative;
     padding-top: 100px;
     text-transform: uppercase;
     color: $important;
     border-color: $important;

     &::after {
       content: "";
       position: absolute;
       background-image: url('../../img/cropped-logo-niubiz.png');
       background-repeat: no-repeat;
       background-size: 177px 41px;
       width: 100%;
       left: 23%;
       height: 50px;
       top: 25px;
     }
   }


   .amplify-button[data-variation=primary] {
     background-color: $important;
     margin: 25px 0 !important;

   }

   .amplify-button[data-size=small] {
     color: $important;
   }

   .amplify-input {
     max-height: 40px;
     border: 1px solid #BCBCBC;
     box-sizing: border-box;
     border-radius: 2px;
   }

   [data-amplify-authenticator] [data-amplify-router] [data-amplify-footer] {
     display: none;
   }

   [data-amplify-authenticator] [data-amplify-container] {
     width: 25rem;
   }

   button.amplify-button.amplify-field-group__control {
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     color: white !important;
     background: $btn;
     border-radius: $btn;
     border-color: $btn;

     border-radius: 40px;
     font-style: normal;
     font-weight: bold;
     font-size: 13px;
     line-height: 100%;
     width: 100%;
     padding: 14px 24px;

     &:hover {
       background: #03A9F4;
       border-radius: #03A9F4;
       border-color: #03A9F4;
       color: white;
     }
   }

   button.amplify-button.amplify-field-group__control.amplify-field__show-password {
     max-height: 40px;
     box-sizing: border-box;
     border-radius: 2px;
   }
 }

 [data-amplify-authenticator][data-variation=modal]::before {
   background-image: none;

 }

 #root button.amplify-button.amplify-field-group__control {
   color: white !important;

   &::after,
   &::before {
     color: white !important;

   }
 }

 .amplify-button.amplify-field-group__control[data-loading=true]:disabled {
   color: white !important;

   span {
     color: white !important;

   }

   &::after,
   &::before {
     color: white !important;

   }

 }

 .react-tooltip-lite {
   padding: 0 !important;
 }

 button.amplify-button.amplify-field-group__control.amplify-alert__dismiss {
   width: max-content !important;
 }

 [data-amplify-authenticator][data-variation=modal] {
   max-width: 100vw !important;
   width: 100vw !important;
   display: flex !important;
   overflow-x: hidden !important;
   padding-top: 0 !important;
 }

 #root [data-amplify-authenticator] [data-amplify-container] {
   width: 21rem !important;
   margin: auto !important;
   padding: 10px !important;
 }

 [data-amplify-authenticator][data-variation=modal]::before {
   display: none;
 }

 .List {
   display: flex;
   flex-direction: column;
   height: 600px;
   overflow-x: auto;
 }

 .modal-body {
   .container {
     padding: 0 !important;
   }
 }

 .mb-24 {
   margin-bottom: 24px !important;
 }

 .ml-24-i {
   margin-left: 24px !important;
 }

 .card-body {
   .btn-close {
     position: relative;
     top: 0;
   }
 }

 .modal-header {
   .btn-close {
     position: relative;
     top: -4px;
   }
 }

 .pr-15 {
   padding-right: 15px;
 }

 #root .amplify-tabs-item[data-state=active] {
   position: relative;
   padding-top: 100px;
   text-transform: uppercase;
   color: #03A9F4;
   border-color: #03A9F4;
   cursor: default !important;
 }

 .flex-wrap.card__form__title {
   @media (min-width: 900px) {
     flex-wrap: nowrap !important;

   }

 }

 input[type="checkbox"] {
   border-radius: 0.25em !important;
 }

 .select-disabled {
   background: #D3D3D3 !important;
   border-color: #D3D3D3 !important;
   color: #A6A6A6 !important;
   cursor: auto !important;
 }