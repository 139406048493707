.modal-title {
    font-weight: 600;
}

.modal-content {
    background: #FFFFFF;
    border: 1px solid $b_footer;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

.modal-header {
    border-bottom: none;
    padding: 0;
    position: absolute;
    right: 24px;
    top: 32px;
    z-index: 999;
}

.modal-body {
    padding: 24px;
}

.modal-footer {
    padding-top: 32px;

    padding-bottom: 32px;

}

.modal-header .btn-close {
    opacity: 1;

    font-size: 16px;
    color: black;
}

.form-label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 100%;
    margin-bottom: 4px;
}

.form-control,
input,
select,
textarea,
.form-select {
    min-height: 40px;
    border: 1px solid $b_footer;
    border-radius: 34px;
    box-sizing: border-box;
    resize: none;
}
textarea{
    padding: 0.375rem 0.75rem;
}
.form-select {
    background: url("../img/arrowSelect.png") no-repeat right #fff;
    padding-left: 16px;
}


.modal {

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 36px;
    }

    &__descripcion {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
    }

    &__btn {
        margin: 36px 0;
    }



    &__suscribe {
        .modal__title {
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 100%;
            margin-bottom: 36px;
            text-align: left;


        }

        .modal__btn {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 15px 0;
            margin-top: 20px;

        }

        .modal__body {
            @include desktop {
                padding: 0 4rem;
            }
        }

        .btn {
            width: max-content;
            min-width: 136px
        }

     

    }

    &__box {
        z-index: 9999;

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100vh;
            background-color: black;
            opacity: 0.5;
            z-index: -1;
            top: 0;
        }
    }
}

.modal-dialog {
    max-width: 800px;
}

.ml-24 {
    margin-left: 24px;
}
.mb-24{
    margin-bottom: 24px;
}