.pagination{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 100%;
&__items{
  display: flex;
  margin-left: 28px;
  align-items: center;
}
&__box, li{
  font-weight: 700;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */

display: flex;
align-items: center;
text-align: center;

justify-content: center;

color: #0F206C;
border: 1px solid #0F206C;
border-radius: 4px;
width: 32px;
height: 32px;
margin-right: 6px;
background: white;
&:last-child{
  margin-right: 0;
}
&.selected{
  background: #03A9F4;
  color: white;
}
}
&__after,&__before, .previous , .next{
  a{
    color: #03A9F4;

  }
  [aria-disabled="true"]{
    color: #878787;

  }
  color: #878787;
}
&__after{
  margin-left: 20px;
}
&__before{
 
  margin-right: 20px;

}
}