@import "bases.scss";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    background: $btn;

    border-color: $btn;
    border-style: solid;
    border-radius: 40px;
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    width: max-content;
    padding: 13.5px 22px;


    &:hover {
        background: #5769BD;
        border-radius: #5769BD;
        border-color: #5769BD;
        color: white;
    }

    &__gradient {
        color: white !important;
        border: none;
        background: #08133A ;
        box-shadow: 0px 6px 12px 0px rgba(28, 77, 161, 0.1803921569);
        &:hover{
            border: none;
            background: #5769BD;
            box-shadow: 0px 6px 12px 0px rgba(28, 77, 161, 0.1803921569); 
        }
    }

    &__float-responsive {
        width: 100vw;
        padding: 0;

        &__container {
            padding: 16px 16px 24px 16px;

            margin: 0 auto;
            display: flex;
            justify-content: center;
            width: 100%;
            max-width: 1600px;

            @include desktop {
                justify-content: flex-end;

                padding: 24px 60px;
            }

            @include tablet-l {}



            @include lg-min {
                padding: 24px 80px;
            }
        }

        &__content {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12);

            @include desktop {
                background: #FFFFFF;
                justify-content: flex-end;
                bottom: 0;
                width: 100vw;

            }

        }

    }

    &__close {
        color: #0F206C !important;
        background: white !important;
        border: transparent ;
        width: 100%;

        &:hover {
            color: #0F206C;
            background: white;
            border: transparent;
        }
    }

    &__logout {
        border-style: solid;
        background: #0F206C;
        border-color: #0F206C;
        color: $white;
        border-radius: 40px;

        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;

        &:hover {
            background: #5769BD;

            border-color: #5769BD;
            color: $white;
            border-radius: 40px;
        }
    }

    &--w {
        width: 100%;
    }

    &.btn-outline-secondary {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        background: #fff;

        border-color: #212121;
        color: #212121;
        border-radius: 40px;

        &:hover {
            background: #fff;
            color: #373737;

            border-color: #373737;
        }
    }

    &.btn-extra {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        background: transparent;

        border: transparent;
        color: #0F206C;
        border-radius: 40px;

        &:hover {
            background: transparent;

            border: transparent;
            color: #5769BD;
        }
    }

    &.btn-disable-line {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        background: transparent;

        border: transparent;
        color: #0F206C;
        border-radius: 40px;

        &:hover {
            background: transparent;

            border: transparent;
            color: #5769BD;
        }
        
    }

    &.btn-secondary {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        background: #D3D3D3;
        border-color: #D3D3D3;
        color: #A6A6A6;
        border-radius: 40px;
        cursor: no-drop;

        &:hover {
            background: #D3D3D3;
            color: #A6A6A6;

            border-color: #D3D3D3;
        }
    }

    &__checkbox {
        min-height: 25px;
        min-width: 25px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 100%;

        input {
            margin-right: 10px;
            min-height: 25px;
            min-width: 25px;
        }

        label {
            display: flex;
            align-items: center;
            color: #878787;

        }

        &.active {
            label {
                color: #212121;

            }
        }
    }
}

.status__label {
    @media only screen and (max-width: 1355px) and (min-width: 1100px) {
        margin: 0 15px;


    }
}

.status__item {

    @media only screen and (max-width: 1355px) and (min-width: 1000px) {
        margin-right: 15px;
        margin-left: 0;
        font-size: 12px;


    }
}

.head-container-btn {

    @media only screen and (max-width: 1355px) and (min-width: 1100px) {
        display: flex;
        flex-wrap: nowrap;
        width: 55%;

        .btn {
            width: max-content;
            margin-left: -5px;
        }

    }

}

@media screen and (max-width: 768px) {
    .button-saved {
        width: 100%;
    }
}

.modal__btn {
    @media only screen and (max-width: 1355px) {
        button {
            margin-bottom: 16px;
            width: 100%;
        }

    }
}