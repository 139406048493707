.card {
    &__btnBox {
        display: flex;
        padding: 16px;
        border: 1px solid $b_footer;
        border-radius: 4px;
        flex-direction: column;
        width: 100%;

        &__text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            margin-bottom: 16px;

            @include desktop {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        .btn {
            width: 85%;
            margin: 0 auto;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            @include desktop {
                margin: 0;
                margin-right: 24px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @include desktop {

            flex-wrap: wrap;
            margin-bottom: 20px;
            flex-direction: initial;
            justify-content: flex-start;

            .btn {
                width: max-content;
            }
        }
    }

    &__form {
        border: 1px solid $b_footer;
        border-radius: 2px;

        &__title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            margin-bottom: 0;
        }

        &__description {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
        }

        &__head {
            padding: 17px 20px;
            border: 0;
            border-bottom: 1px $b_footer;
            border-style: solid;
            font-weight: 700;
            font-size: 14px;

        }

        &__body {
            padding: 17px 20px;
        }

        &__list {
            display: flex;
            flex-direction: column;

        }

        &__item {
            margin-bottom: 18px;
        }
    }

    &__col {
        @include tablet {

            width: 30% !important;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;

            &:last-child {
                padding-left: 0;
                padding-right: 20px;
            }

            &-b {
                width: 16% !important;
            }
        }

    }

    &__component {
        min-height: 40px;
        border: 1px solid #BCBCBC;
        box-sizing: border-box;
        border-radius: 2px;

        .card-body {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                position: relative;
                right: -25px;
            }
        }

    }

    &__description {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;


        &__b {
            flex-direction: column;

            .card__description__head {
                margin-bottom: 24px;
            }

            @include desktop {
                .card__description__body {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .status__label {
                    margin: 0 !important;
                    margin-bottom: 16px;
                }

                .status__text {
                    text-transform: initial;
                }
            }
        }

        @include desktop {
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin-bottom: 20px;
        }

        &__body {
            background: transparent;

            @include desktop {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

        }

        &__text {
            margin-bottom: 16px;

            @include desktop {
                margin-bottom: 0;

            }
        }

        &__head {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 28px;

            .btn {
                width: 100%;
                min-width: max-content;
            }

            @include desktop {
                margin-bottom: 0;
            }

            @include lg-min {
                .row {
                    .btn-2 {
                        position: relative;
                        right: -20px;
                    }
                }

                .btn {
                    width: max-content;
                }

            }

            &-b {
                margin-bottom: 37px;
            }
        }

        &__body {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            @include desktop {
                flex-wrap: nowrap;

            }
        }
    }

    &__list {
        margin-bottom: 50px;

        &__head {
            display: flex;
            align-items: center;
            padding-bottom: 0;
            justify-content: space-between;
            min-width: 25%;

            @include desktop {
                padding: 16px;
                padding-bottom: 0;

            }

            &-a {
                padding: 0;
            }

        }

        &__body {
            padding-top: 0;
            display: flex;
            width: 100%;
            flex-direction: column;
            padding-bottom: 24px;

            @media (max-width: 768px) {
                justify-content: flex-end;
                align-items: flex-start;
            }

            @include desktop {
                padding: 16px;
                padding-top: 0;

            }

            &-a {
                border-bottom: 1px solid $b_footer;
                border-radius: 2px;
                padding-left: 0;
                padding-right: 0;

                .card__list__text {
                    margin-bottom: 0;
                }
            }
        }

        &__container {
            border-bottom: 1px solid $b_footer;
            border-radius: 8px;

            @include desktop {
                border: 1px solid $b_footer;

            }

            &--a {
                position: relative;
                margin: 0 8px;
                margin-bottom: 16px;
                border-radius: 8px;
                border: 1px solid #BCBCBC;
                padding: 16px 8px !important;

                .accordion__panel {
                    margin: 8px 0;
                    padding: 0 !important;
                }

                .card__list__head,
                .card__list__body {
                    padding: 0;

                }

                .accordion__panel {
                    border: 0;
                    padding: 0;
                }

                .card__list__title {
                    text-transform: capitalize !important;
                }
            }

            &--b {
                border: none;
                position: relative;
                padding: 16px 0;

                &::after {
                    content: '';
                    width: calc(100% + 16px);
                    height: 1px;
                    background-color: $b_footer;
                    top: 0;
                    position: absolute;
                    left: -8px;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                .card__list__head,
                .card__list__body {
                    padding: 0;

                }

                .card__list__title {
                    margin-bottom: 0;
                    margin-top: 0;
                    font-size: 14px !important;
                    text-transform: capitalize !important;
                    line-height: 18px;
                    font-weight: 400 !important;
                }
            }

            &__item {
                margin-bottom: 18px;
            }
        }

        &__top {
            display: flex;
            cursor: pointer;
            flex-direction: column;
            position: relative;

            &--b {
                display: flex;
                padding: 16px;
                justify-content: space-between;

                @media (max-width: 768px) {
                    flex-direction: column;
                }

                .card__list__head {
                    flex-direction: column;
                    padding: 0;
                    justify-content: flex-start;
                    text-align: left;
                    align-items: flex-start;

                }

                .card__list__body {
                    flex-direction: column;
                    padding: 0
                }

            }
        }

        &__bottom {
            margin: 24px 1rem;
            padding: 24px 1rem;
            border: 1px solid $b_footer;
            width: 100%;

            @include desktop {
                margin: 24px 3rem;
                padding: 24px 3rem;
                width: auto;
            }
        }

        &__btn {
            width: 23px;
            height: 23px;

            &__container {
                position: absolute;
                bottom: 16px;
                right: 16px;
            }

            &--op {
                opacity: 0;
                width: 24px;
                height: 24px;
            }
        }

        &__status {
            &__item {
                display: flex;
                font-style: normal;
                font-size: 14px;
                line-height: 100%;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                &--a {
                    display: flex;
                    align-items: center;
                }

                &--b {
                    justify-content: space-between;
                    padding-right: 30px;
                    width: 100%;

                    .card__list__title {
                        margin-bottom: 0;
                        margin-top: 0;
                        font-size: 18px;
                        text-transform: uppercase;
                        line-height: 18px;
                    }
                }

                @include desktop {
                    flex-direction: initial;
                    align-items: center;

                }

            }

            &__img {
                margin-right: 5px;
                position: relative;
                top: -1px;
                width: 16px;

                img {
                    width: 100%;
                }


            }

            &__text {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                text-transform: uppercase;

            }
        }

        &__updates {
            display: flex;
            margin-top: 16px;

            @include desktop {
                margin-top: 0;
                margin-left: 57px;
                margin-bottom: 0;

            }
        }

        &__update {
            display: flex;

            &__item {
                display: flex;
                align-items: center;
                margin-right: 17px;

                @include desktop {
                    margin-right: 30px;

                }

                &:last-child {
                    margin-right: 0;
                }
               
            }

            &__img {
                margin-right: 5px;
                position: relative;
                color: $btn;
                &:hover {
                    background: transparent;
        
                    border: transparent;
                    color: #5769BD;
                }

            }

            &__text {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 100%;
                color: $btn;
                text-transform: initial;
                &:hover {
                    background: transparent;
        
                    border: transparent;
                    color: #5769BD;
                }
            }
        }

        &__title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            margin-bottom: 8px;
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            span {
                margin: 0 7px;

            }

            &-b {
                font-weight: normal;
            }

            .content-a {
                flex-wrap: wrap;
                align-items: center;

                display: flex;

                .status__item {
                    margin-left: 0;

                }

            }
        }

        &__subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 100%;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: initial;
                align-items: center;

            }
        }

        &__text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            margin-bottom: 18px;

            &-b {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
            }
        }

        &__detail {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            margin-bottom: 16px;
        }

        &__rca {
            display: flex;
            justify-content: center;
            align-items: center;

            &__items {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .label {
                    color: $important;
                    font-weight: 500;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 5.33px;
                        font-size: 13px;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 21.3px;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        margin: auto;

        @include tablet {
            padding: 10px 0;


        }

        h2 {
            font-size: 14px;
            font-weight: 700;
            text-align: start;
        }

        @media (max-width: 768px) {
            display: none;
        }

        &__client {
            width: 35%;
            min-width: 25%;
        }

        &__select {
            width: 24%;
        }

        &__rca {
            width: 20%;
            min-width: 200px;
        }
    }

}

.accordion__panel {
    margin: 0 8px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid $b_footer;
    padding: 8px !important;
}

.accordion__panel--a {

    padding: 0 !important;
}

.search {
    margin-top: 16px;
    margin-bottom: 16px;

    @include desktop {
        margin-top: 0;
        margin-bottom: 0;

        position: absolute;
        width: 40%;
        right: 12px;
        top: -54px;
    }

    &__icon {
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 9px;
            color: #DADADA;
            font-size: 19px;

        }
    }
}
.pb_28{
    padding-bottom: 28px;
}