@font-face {
  font-family: 'icomoon';
  src:  url('../icomoon/fonts/icomoon.eot?ye7rbr');
  src:  url('../icomoon/fonts/icomoon.eot?ye7rbr#iefix') format('embedded-opentype'),
    url('../icomoon/fonts/icomoon.ttf?ye7rbr') format('truetype'),
    url('../icomoon/fonts/icomoon.woff?ye7rbr') format('woff'),
    url('../icomoon/fonts/icomoon.svg?ye7rbr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
  content: "\e905";
  
}
.icon-minus:before {
  content: "\e906";
  
}
.icon-before .path1:before {
  content: "\e907";
}
.icon-before .path2:before {
  content: "\e908";
  margin-left: -1em;
 
}
.icon-less .path1:before {
  content: "\e909";
  
}
.icon-less .path2:before {
  content: "\e90a";
  margin-left: -1em;
 
}
.icon-more1 .path1:before {
  content: "\e90b";
 
}
.icon-more1 .path2:before {
  content: "\e90c";
  margin-left: -1em;
  
}
.icon-after .path1:before {
  content: "\e90d";

}
.icon-after .path2:before {
  content: "\e90e";
  margin-left: -1em;
  
}
.icon-down:before {
  content: "\e90f";
  
}
.icon-left:before {
  content: "\e910";
  
}
.icon-right:before {
  content: "\e911";
  
}
.icon-plantilla:before {
  content: "\e912";
}
.icon-plus:before {
  content: "\e913";
  
}
.icon-search:before {
  content: "\e914";
  
}
.icon-see:before {
  content: "\e915";
  
}
.icon-arrow-left:before {
  content: "\e916";
  
}
.icon-arrow-right:before {
  content: "\e917";
  
}
.icon-arrow-up:before {
  content: "\e918";
  
}
.icon-chevron-up:before {
  content: "\e919";
  
}
.icon-download:before {
  content: "\e91a";
  
}
.icon-upload:before {
  content: "\e904";
  
}
.icon-arrow-top:before {
  content: "\e900";
}
.icon-pencil-create:before {
  content: "\e901";
}
.icon-trash:before {
  content: "\e902";
  
}
.icon-more:before {
  content: "\e903";
  
}
