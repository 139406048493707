.login {
    &__container {
        width: 100vw;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        width: 250px;
        height: auto;
        margin-bottom: 24px;

    }

    &__card {
        max-width: 314px;
        width: 100%;
        height: 334px;
        border-top: 3px solid #03A9F4;
        box-shadow: 0px 4px 15px 0px #00000066;
        display: flex;
        flex-direction: column;
        padding: 56px 32px;
        text-align: center;

    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 24px;

    }

    &__description {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 24px;

    }


    &__btn {
        width: 100%;
        font-size: 13px;
        font-weight: 700;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px, 24px, 10px, 24px;
        border-radius: 100px;
        gap: 8px
    }

}