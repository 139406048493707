@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@font-face {
  font-family: 'Object';
  src: ("../fonts/object/Objective_OTF/Objective-Regular.otf");
  src: url("../fonts/object/Objective_OTF/Objective-Regular.otf?#iefix") format('opentype'), url("../fonts/object/Objective_Woff2/Objective-Regular.woff2") format('woff2'), url("../fonts/raleway/raleway-regular-webfont.woff") format('woff'), url("../fonts/raleway/raleway-regular-webfont.ttf") format('truetype'), url("../fonts/raleway/raleway-regular-webfont.svg#ralewaybold") format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Object';
  src: url("../fonts/object/Objective_OTF/Objective-Light.otf");
  src: url("../fonts/object/Objective_OTF/Objective-Light.otf") format('opentype'), url("../fonts/raleway/raleway-light-webfont.woff2") format('woff2'), url("../fonts/raleway/raleway-light-webfont.woff") format('woff'), url("../fonts/raleway/raleway-light-webfont.ttf") format('truetype'), url("../fonts/raleway/raleway-light-webfont.svg#ralewaybold") format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Object';
  src: url("../fonts/object/Objective_OTF/Objective-Medium.otf");
  src: url("../fonts/object/Objective_OTF/Objective-Medium.otf?#iefix") format('opentype'), url("../fonts/raleway/raleway-medium-webfont.woff2") format('woff2'), url("../fonts/raleway/raleway-medium-webfont.woff") format('woff'), url("../fonts/raleway/raleway-medium-webfont.ttf") format('truetype'), url("../fonts/raleway/raleway-medium-webfont.svg#ralewaybold") format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Object';
  src: url("../fonts/object/Objective_OTF/Objective-Bold.otf");
  src: url("../fonts/object/Objective_OTF/Objective-Bold.otf?#iefix") format('opentype'), url("../fonts/raleway/raleway-bold-webfont.woff2") format('woff2'), url("../fonts/raleway/raleway-bold-webfont.woff") format('woff'), url("../fonts/raleway/raleway-bold-webfont.ttf") format('truetype'), url("../fonts/raleway/raleway-bold-webfont.svg#ralewaybold") format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: ("../fonts/lato/Lato-Regular.ttf");
  src: url("../fonts/lato/Lato-Regular.ttf?#iefix") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: ("../fonts/lato/Lato-Light.ttf");
  src: url("../fonts/lato/Lato-Light.ttf?#iefix") format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face { 
  font-family: 'Lato';
  src: ("../fonts/lato/Lato-Black.ttf");
  src: url("../fonts/lato/Lato-Black.ttf?#iefix") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: ("../fonts/lato/Lato-Black.ttf");
  src: url("../fonts/lato/Lato-Black.ttf?#iefix") format('truetype');
  font-weight: bold;
  font-style: normal;
}