.table {

    &__head,
    &__body {
        padding: 16px;
        border: 1px solid $b_footer;
        border-radius: 2px 2px 0px 0px;
        display: flex;
        align-items: center;
    }

    &__head {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        
    }

    &__body {
        border-top: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        flex-direction: column;

    }

    

    &__col {
        width: 100%;
        display: flex;
        &-a {
            width:70% ;
        }

        &-b{
            width: 30%;
            display: flex;
            justify-content: flex-end;
            .btn__checkbox,.form-check-input:checked[type=checkbox],.form-check-input[type=checkbox] {
                margin-right: 0;
            }
        }
        &__title{
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;            
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
        &__description{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
        }
    }
}