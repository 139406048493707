.card-body {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;

}

.bg-primary {
    background-color: $important !important;
}

.bg-secondary {
    background-color: $b_footer !important;
}

.form-check-input:checked {
    background-color: $important !important;
    border-color: $important !important;
}
.form-check-input {
   margin-top: -2.5px;
}
.modal-footer{
    border-top: 0;
    button{
        &:last-child{
            margin-left: 24px;
        }
    }
}
