.success{
    min-height: 206px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    i{
        color: $btn;
        font-size: 38px;
        margin-bottom: 48px;
    }
}