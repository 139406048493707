.file {
    &__label{
        font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 100%;
    }
    &__select {
        border: 1px dashed #BCBCBC;
        border-radius: 2px;
        width: 100%;
        height: 88px;
        
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-close{
            margin-left: 5px;
        }
        &__text{
            font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;

        color: #03A9F4;
        }
    }

    label {
        max-width: 752px;
        width: 100%;
        height: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 2px;
    }

    &_zona {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('./img/select_document.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include desktop {
            background-size: contain;

        }
    }

    .file-types {
        display: none;
    }

    span {
        display: none;

    }

    svg {
        display: none;
    }
}