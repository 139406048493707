.banner{
    width: 100%;
    img{
        width: 100%;
    }
    &.a,&.b,&.c,&.d,&.e,&.f{
        display: none;

        
    }
    &.a{
        display: block;
    }
    @include mobile-min {
        &.a{
            display: block;
        }
        // background-image: url('../img/BANNER-01.png');

    }
    @include mobile {
        &.a{
            display: none;
        }
        &.b{
            display: block;
        }
        // background-image: url('../img/BANNER-02.png');

    }
    @include tablet {
        &.b{
            display: none;
        }
        &.c{
            display: block;
        }
        // background-image: url('../img/BANNER-03.png');

    }
    @include tablet-l {
        &.c{
            display: none;
        }
        &.d{
            display: block;
        }
        // background-image: url('../img/BANNER-04.png');

    }
    @include desktop{
        &.d{
            display: none;
        }
        &.e{
            display: block;
        }
        // background-image: url('../img/BANNER-05.png');

    }
    @include lg-min  {
        &.e{
            display: none;
        }
        &.f{
            display: block;
        }
        // background-image: url('../img/BANNER-06.png');

    }
}