.badge {
    background: #BDE4F9;
    border-radius: 2px;
    padding: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 0 5px;
    color: #212121;
    height: fit-content;
}