//colors
$primary-ligth: #909090;
$primary: #000000;
$primary-bold : #373737;
$important-ligth:#c3c3c3;
$important:#0F206C;
$btn:#0F206C;
$btn_b:#FFFFFF;
$btn_c:#0F206C;
$b_header:#212121 ;
$b_footer: #E9E9E9;

//fonts
$primary-neutral: #E5E5E5;
$base-font-family:"Lato";

//sizes
$screen-xs-min: 360px;
$screen-xs: 480px;
$tablet-width: 600px;
$screen-sm-min: 768px;
$desktop-width: 992px;
$screen-lg-min: 1336px;