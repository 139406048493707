.loader {
  font-size: 20px;
  margin: 45% auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  &_b{
    font-size: 7px;

  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background: white;
    padding: 20px;
    text-align: center;
    margin-bottom: 15px;

    &_min {
      height: 150px;
    }
    &__b{
      height: auto;
      width: auto;
    }
  }
}

@-webkit-keyframes load4 {

  0%,
  100% {
    box-shadow: 0em -3em 0em 0.2em $btn, 2em -2em 0 0em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 0em $btn;
  }

  12.5% {
    box-shadow: 0em -3em 0em 0em $btn, 2em -2em 0 0.2em $btn, 3em 0em 0 0em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  25% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 0em $btn, 3em 0em 0 0.2em $btn, 2em 2em 0 0em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  37.5% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 0em $btn, 2em 2em 0 0.2em $btn, 0em 3em 0 0em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  50% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 0em $btn, 0em 3em 0 0.2em $btn, -2em 2em 0 0em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  62.5% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 0em $btn, -2em 2em 0 0.2em $btn, -3em 0em 0 0em $btn, -2em -2em 0 -0.5em $btn;
  }

  75% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 0em $btn, -3em 0em 0 0.2em $btn, -2em -2em 0 0em $btn;
  }

  87.5% {
    box-shadow: 0em -3em 0em 0em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 0em $btn, -3em 0em 0 0em $btn, -2em -2em 0 0.2em $btn;
  }
}

@keyframes load4 {

  0%,
  100% {
    box-shadow: 0em -3em 0em 0.2em $btn, 2em -2em 0 0em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 0em $btn;
  }

  12.5% {
    box-shadow: 0em -3em 0em 0em $btn, 2em -2em 0 0.2em $btn, 3em 0em 0 0em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  25% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 0em $btn, 3em 0em 0 0.2em $btn, 2em 2em 0 0em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  37.5% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 0em $btn, 2em 2em 0 0.2em $btn, 0em 3em 0 0em $btn, -2em 2em 0 -0.5em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  50% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 0em $btn, 0em 3em 0 0.2em $btn, -2em 2em 0 0em $btn, -3em 0em 0 -0.5em $btn, -2em -2em 0 -0.5em $btn;
  }

  62.5% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 0em $btn, -2em 2em 0 0.2em $btn, -3em 0em 0 0em $btn, -2em -2em 0 -0.5em $btn;
  }

  75% {
    box-shadow: 0em -3em 0em -0.5em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 0em $btn, -3em 0em 0 0.2em $btn, -2em -2em 0 0em $btn;
  }

  87.5% {
    box-shadow: 0em -3em 0em 0em $btn, 2em -2em 0 -0.5em $btn, 3em 0em 0 -0.5em $btn, 2em 2em 0 -0.5em $btn, 0em 3em 0 -0.5em $btn, -2em 2em 0 0em $btn, -3em 0em 0 0em $btn, -2em -2em 0 0.2em $btn;
  }
}