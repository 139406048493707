$screen-xs-min: 361px;
$screen-xs: 480px;
$tablet-width: 600px;
$screen-sm-min: 768px;
$desktop-width: 1100px;
$screen-lg-min: 1350px;
$screen-xl: 1650px;
$screen-xxl: 1730px;
$screen-xxxl: 1830px;
$screen-xxxxl: 1900px;
@mixin mobile-min {
    @media (min-width: #{$screen-xs-min}){
        @content;
    }
}
@mixin mobile {
    @media (min-width: #{$screen-xs}){
        @content;
    }
}
@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin tablet-l {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}){
        @content;
    }
}
@mixin lg-min {
    @media (min-width: #{$screen-lg-min}){
        @content;
    }
}
@mixin xl {
    @media (min-width: #{$screen-xl}){
        @content;
    }
}
@mixin xxl {
    @media (min-width: #{$screen-xxl}){
        @content;
    }
}
@mixin xxxl {
    @media (min-width: #{$screen-xxxl}){
        @content;
    }
}
@mixin xxxxl {
    @media (min-width: #{$screen-xxxxl}){
        @content;
    }
}