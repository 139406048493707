.filter {
    &__quarterly {
        &__item {
            margin-bottom: 24px;

            @include desktop {
                width: 100%;
                margin-bottom: 0;
                display: flex;
            }

        }

        &__container {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: relative;

            @include desktop {
                flex-direction: initial;
                margin-bottom: 24px;
            }
        }

        &__year {
            @include desktop {
                position: absolute;
                width: max-content;
                right: 0;
                max-width: 200px;
            }

        }

        &__mounth {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            @include desktop {
                justify-content: center;
            }

            &__text {
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 120%;
                margin: 0 16px;
                text-transform: capitalize;
            }

            &__arrow {
                cursor: pointer;
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                background-color: #D3D3D3;
                border-radius: 50%;
                color: #A6A6A6;
                
                

                &.active {
                    background: #0F206C;
                    color: white;
                    &:hover {
                        background: #5769BD;
                        border-radius: #5769BD;
                        border-color: #5769BD;
                        color: white;
                    }
                }
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        border: 1px solid $b_footer;
        margin-bottom: 40px;

        @include desktop {
            flex-direction: initial;
            justify-content: space-between;
        }

    }

    &__item {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        button {
            width: 100%;
        }

        @include desktop {
            width: 22%;
            margin-bottom: 0;
        }
    }

    &__btn {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @include desktop {
            justify-content: space-around;
            flex-wrap: nowrap;

        }

        &__item {
            width: 100%;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            button {
                width: 100%;
            }

            @include desktop {
                width: 44%;
                margin-bottom: 0;


            }
        }

    }

}