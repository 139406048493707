.steps {
    &__detail {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        text-align: center;

        &__detail {
            color: $important;
            margin-left: 5px;
        }
    }
    &__container{
        margin: 24px 0;
    }
}